@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.HeaderWrapper {
    background-image: linear-gradient(118deg, rgba(231,230,255,1) 0%, rgb(32, 51, 197) 35%, rgb(43, 65, 188) 72%, rgb(0, 0, 0) 100%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 10px;
}
.NameAndLogo {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Logo{
    height: 50px;
    object-fit: contain;
}

.NKGSCCL_Logo{
    height: 50px;
    padding-left: 20px;
}
.NotificationBar{
    width: 40px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.Brand{
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    color: #ffffff;
    /* padding-left: 10px; */
    flex: 1;
}

.NotificationBell {
    border: 5px solid #ffffff00; 
    padding: 1px;
    margin: 5px;
    border-radius: 50%;
    transition:cubic-bezier(0.19, 1, 0.22, 1) 1s;
}

.NotificationBell:hover {
    border: 1px solid #ffffffff;
    padding: 10px;
    margin: 0px;
}