@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300&display=swap);
@import url(https://cdn.syncfusion.com/ej2/material.css);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300&display=swap);
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.App {
  text-align: center;
  background-color: #323743;
}
.multi-select{
  width: 200px;
}
.dropdown-heading-value, .dropdown-content{
  color: #000 !important;
  text-align: left !important;
}

.DropDown_DropDown__Dyajs {
    margin: 5px;
    flex: 1, 1
}
.DropDown_DropDownContainer__1rPZY {
    max-width: 150px;
}
.Header_HeaderWrapper__2GfgS {
    background-image: linear-gradient(118deg, rgba(231,230,255,1) 0%, rgb(32, 51, 197) 35%, rgb(43, 65, 188) 72%, rgb(0, 0, 0) 100%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 10px;
}
.Header_NameAndLogo__2aype {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.Header_Logo__3b1hA{
    height: 50px;
    object-fit: contain;
}

.Header_NKGSCCL_Logo__2QMXT{
    height: 50px;
    padding-left: 20px;
}
.Header_NotificationBar__jgjJd{
    width: 40px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.Header_Brand__2Zoes{
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    color: #ffffff;
    /* padding-left: 10px; */
    flex: 1 1;
}

.Header_NotificationBell__31_TE {
    border: 5px solid #ffffff00; 
    padding: 1px;
    margin: 5px;
    border-radius: 50%;
    transition:cubic-bezier(0.19, 1, 0.22, 1) 1s;
}

.Header_NotificationBell__31_TE:hover {
    border: 1px solid #ffffffff;
    padding: 10px;
    margin: 0px;
}
a {
    text-decoration: none;
}

.SideBar_SideBar__3aejb {
    display: flex;
    flex-direction: column;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 1s;
    background-color: #414755;
}

.SideBar_SideBarToggleButton__3tZtF {
    padding: 20px;
}

.SideBar_UserAvatar__fNPr4{
    height: 64px;
    width: 64px;
    border-radius: 32px;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 1s;
}

.SideBar_UserInfo__1D0PR {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    padding-left: 25px;
    transition: cubic-bezier(0.19, 1, 0.22, 1) 1s;
}

.SideBar_UserNameAndDesignation__2Qr6N {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    flex: 1 1;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
}

.SideBar_UserName__DhJt9 {
    font-size: 14px;
    color: #cccccc;
    text-align: left;
}

.SideBar_UserDesignation__1MooW {
    font-size: 13px;
    color: #bbbbbb;
}

.SideBar_AccountSettingsWrapper__1jDJg {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.SideBar_AccountSettingsButton__3osBH {
    border: 1px solid #ccc;
    padding: 10px;
    color: #ccc;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 750ms;
}
.SideBar_AccountSettingsButton__3osBH:hover {
    background-color: #cccccc1c;
    padding-left: 20px;
    padding-right: 20px;
}

.SideBar_SideBarOption__4s61B {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 500ms;
}
.SideBar_SideBarOption__4s61B:hover {
    background-color: #4f5467;
    padding-left: 30px;
}

.SideBar_SideBarSubOption__1adoL {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 10px 10px 20px;
    border-left: 10px solid #757b94;
    transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 500ms;
}
.SideBar_SideBarSubOption__1adoL:hover {
    background-color: #4f5467;
    padding-left: 20px;
}

.SideBar_SideBarOptionActive__1BX8H {
    background-color: #4f5467;
    padding-left: 30px;
}

.SideBar_SideBarOptionTitle__ruwfX {
    padding-left: 20px;
    color: #ccc;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    flex: 3 1;
    text-align: left;
}

.SideBar_SideBarOptionIcon__1BMYy {
    flex: 1 1;
    font-size: 20px;
}
.Card_Card__3e6h1 {
    z-index: 1;
    width: 220px;
    height: 130px;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    border-radius: 20px;
    cursor:default;
    transition: cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
    margin: 10px 10px;
}
.Card_CardRow__-lXK4 {
    display: flex;
    flex: 2 1;
    flex-direction: row;
    justify-content: space-between;
    align-items:space-between;
}

.Card_CardRow__-lXK4:first-child {
    flex: 1 1;
    align-items: center;
}

.Card_CardRow__-lXK4:last-child {
    justify-content: flex-end;
    align-items: flex-end;
}

.Card_Value__2MgSx {
    font-size: 2em;
    justify-content: flex-end;
    align-items: flex-start;
}

.Card_CardRow__-lXK4:first-child > .Card_Title__33Lpf {
    font-size: 1.1em;
}

.Card_CardRow__-lXK4:last-child > .Card_Title__33Lpf {
    font-size: 1.2em;
}

.Card_Description__1Rraz {
    text-align: justify;
    font-size: 0.75em;
    overflow: hidden;
}

.Card_Icon__1FSsR {
    font-size: 1.5em;
}

.FilterBar_FilterBar__26qOi {
  content: "";
  background-color: #ccc;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 40px;
  z-index: 3;
}
.FilterBar_InfoText__148yj {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  padding-bottom: 10px;
}

.FilterBar_FilterLabel__2zJhy {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 10px;
}

.FilterBar_SideBarOptionIcon__2AV_k {
  flex: 1 1;
  font-size: 20px;
}

.FilterBar_Chips__2LF5v {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 500ms;
}

.FilterBar_CenteredButtonWrapper__3QRKY {
  width: auto;
  height: auto;
  display: flex;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  margin-bottom: -32px;
  z-index: 2;
}

/* .CenteredButton::before {
  content: "";
  background-color: #fff;
  padding: 5px;
  width: 20px;
  height: 1px;
  border-radius:  0% 0% 50% 50%; 
  border: 11px solid whitesmoke;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top-width: 0px;
  margin-right: -23px;
  cursor: pointer;
} */
.FilterBar_CenteredButton__1O3bi{
  cursor: pointer;
  text-align: center;
}


.DateRange_CalenderIcon__3_eLk {
    flex: 1 1;
    font-size: 20px;
    margin-right: 10px;
}
.DashBoard_CardContainer__1r8tM {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    /* padding-right: 50px; */
}

.DashBoard_DateRangePicker__1gFIk {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    right: 100px;
    top: 20px;
}

.DashBoard_ChartContainers__3cD-q {
    flex: 1 1;
    margin: 10px;
    padding: 0px;
    background-color: #272b34!important;
    color: #dddddd !important;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 500ms !important;
    padding: 5px;
}

.DashBoard_ChartContainers__3cD-q:hover {
    box-shadow: 0px 0px 10px 0px #777777;
}

h1 {
    font-family: "Poppins";
    color: #dddddd;
    text-align: left;
    padding: 10px 20px;
    font-size: 24px;
}

.custom-bg-yellow {
    background-color: yellow !important;
    color: black !important;
}

.custom-bg-yellow:hover {
    background-color: rgba(255, 255, 0, 0.75) !important;
    color: black !important;
}

.custom-bg-red {
    background-color: red !important;
    color: white !important;
}
.custom-bg-red:hover {
    background-color: rgba(255, 0, 0, 0.75) !important;
    color: white !important;
}

.custom-bg-green {
    background-color: green !important;
    color: white !important;
}
.custom-bg-green:hover {
    background-color: rgba(0, 128, 0, 0.75) !important;
    color: white !important;
}

.custom-bg-white {
    background-color: white !important;
    color: black !important;
}

.custom-bg-white:hover {
    background-color: rgba(255, 255, 255, 0.75) !important;
    color: black !important;
}

.custom-bg-black {
    /* background-color: black !important; */
    color: white !important;
}

.custom-bg-black:hover {
    /* background-color: rgba(255, 255, 255, 0.75) !important; */
    color: white !important;
}
.e-grid{
    /* overflow:'scroll'; */
    /* width: '100%'; */
}

.e-grid .e-headercell {
    color: darkblue;
 }
 /* .isLatLong {
    background-color: green;
  } */
.Reports_CalenderIcon__K0Upy {
    flex: 1 1;
    font-size: 20px;
    margin-right: 10px;
}
.Bargraph_ChartContainers__aLQ7q {
    flex: 1 1;
    margin: 10px;
    padding: 0px;
    height: 500px;
    background-color: #272b34!important;
    color: #dddddd !important;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 500ms !important;
    padding: 5px;
}

.Bargraph_ChartContainers__aLQ7q:hover {
    box-shadow: 0px 0px 10px 0px #777777;
}
.MapView_CardContainer__fANqs {
    display: flex;
    position: absolute;
    flex: 1 1;
    margin-top: 100px;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    width: 400px;
    height: calc(100% - 180px);
    bottom: 0px;
    right: 0px;
    justify-content: flex-start;
    align-items: flex-end;
    padding-right: 50px;
    text-align: center;
}

.MapView_DateRangePicker__24Eeo {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    right: 220px;
    top: 20px;
    max-width: 140px;
}

.MapView_ToggleButton__14EmV {
    position: absolute !important;
    right: 70px;
    top: 10px;
    color: white;
}
.styles_container__2NnD6 {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  background-color: #212529;
  font-family: "Roboto", sans-serif;
  /* background-image: linear-gradient(
    90deg,
    rgba(91, 88, 144, 1) 0%,
    rgba(220, 155, 241, 1) 35%,
    rgba(162, 209, 110, 1) 78%,
    rgba(0, 212, 255, 1) 100%
  ); */
}

.styles_SignBox__1pXa_ {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  width: 30%;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  box-shadow: 1px 1px 10px 1px black;
  position: absolute;
  z-index: 2;
  border-radius: 20px;
  /* background-color: #262a32; */
}
.styles_SignBoxBackground__2-NWy {
  background-color: #262a32;
  /* background-image: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(136,136,136,1) 75%, rgba(150,150,150,1) 100%); */
  opacity: 0.5;
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  flex-direction: column;
  z-index: 2;
  padding: 50px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.styles_input_box__2KPPt {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  width: 100%;
  opacity: 1;
}

.styles_pass__zT4zU {
  color: transparent;
}
.styles_pass__zT4zU::selection {
  color: transparent;
}
.styles_input_box__2KPPt:focus {
  outline: none;
  box-shadow: 0px 0px 5px 2px #7460ee;
}

.styles_CheckBoxText__1l4kt {
  -webkit-font-smoothing: subpixel-antialiased;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  flex-direction: row;
  width: 100%;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
}

.styles_loginButton__16RO4 {
  width: 200px;
  padding: 5px;
  margin: 10px 30px;
  border-radius: 5px;
  border: none;
  background-color: #7460ee;
  color: #ffffff;
  font-size: 18px;
  line-height: 1.5em;
  opacity: 1;
}

.styles_loginButton__16RO4:focus {
  outline: none;
}

.styles_Checkbox__IYrqI {
  background-color: #7460ee;
}

